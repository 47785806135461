import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import Layout from './components/Layout';
import React, { useContext } from 'react';
import Login from './components/Login'; // Import Login component
import OnboardingModal from './components/OnboardingModal'; // Import OnboardingModal component

const App = () => {
  const { authTokens, isFirstLogin, setIsFirstLogin } = useContext(AuthContext);


  return (
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={!authTokens ? <Login /> : <Navigate to="/" />}
        />
        <Route
          path="/"
          element={
            authTokens ? (
              <>
                {isFirstLogin && <OnboardingModal setIsFirstLogin={setIsFirstLogin} />}
                <Layout />
              </>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default App;