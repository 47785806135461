// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.taskbar {
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #121212;
  }
  
  .taskbar-button, .file-upload-label {
    background-color: #242424;
    color: #e0e0e0;
    border: none;
    padding: 8px 12px;
    margin-right: 8px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .taskbar-button:hover, .file-upload-label:hover {
    background-color: #121212;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/taskbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".taskbar {\n    display: flex;\n    justify-content: right;\n    margin-bottom: 10px;\n    padding: 10px;\n    background-color: #121212;\n  }\n  \n  .taskbar-button, .file-upload-label {\n    background-color: #242424;\n    color: #e0e0e0;\n    border: none;\n    padding: 8px 12px;\n    margin-right: 8px;\n    cursor: pointer;\n    border-radius: 4px;\n  }\n  \n  .taskbar-button:hover, .file-upload-label:hover {\n    background-color: #121212;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
