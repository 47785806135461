// components/Login.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { backend, setAccessToken, setUserId} from '../api/axios'; // Import the backend axios instance and setAccessToken function
import '../css/login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setAuthTokens, setIsFirstLogin } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();

    // if (username==="fizah" && password ==="fizah"){
    //     setAuthTokens({"access":"fake token"});
    //     setIsFirstLogin(true);
    //     // Optionally, save the access token in cookies
    //     setAccessToken("fake token");
    //     setUserId("fake user id");
    // }
    // else{
       
    //     setError('Invalid username or password');
        
    // }
    try {
      // Use the backend axios instance to make the POST request
      const response = await backend.post('users/login', {
        username,
        password,
      });

      console.log("RESPONSE LOGIN: ",response);
      console.log("RESPONSE STATUS: ",response.status);

      if (response.status === 200) {
        const data = response.data;
        // Set the authentication tokens and first login flag
        setAuthTokens(data.tokens);
        setIsFirstLogin(data.isFirstLogin);
        // Optionally, save the access token in cookies
        setAccessToken(data.tokens.access);
        setUserId(data.user_id);
      } else {
        setError('Invalid username or password');
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 401) {
        setError('Invalid username or password');
      } else {
        setError('An error occurred during login');
      }
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <h2>Login</h2>
        {error && <div className="error">{error}</div>}
        <div className="input-group">
          <label>Username or Email</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
        <div className="support">
          <a href="mailto:support@example.com">Forgot password? Contact Support</a>
        </div>
      </form>
    </div>
  );
};

export default Login;
