import React, { useState } from 'react';
import { backend } from '../api/axios'; // Import the backend axios instance
import '../css/onboardingModal.css';
import Cookies from 'js-cookie';

const OnboardingModal = ({ setIsFirstLogin }) => {
  const [organizationName, setOrganizationName] = useState('');
  const [goal, setGoal] = useState('');
  const [industry, setIndustry] = useState('');
  const [projects, setProjects] = useState('');
  const [files, setFiles] = useState([]); // Updated to store multiple files
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const industries = ['Technology', 'Healthcare', 'Finance', 'Education', 'Other']; // Example industries

  const userId = Cookies.get('userId');

  // Handle file change
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files); // Convert FileList to Array
    setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Add new files to the existing list
  };

  // Remove file from the list
  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file at the given index
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data
    const formData = new FormData();
    formData.append('organization_name', organizationName);
    formData.append('user_id', userId);
    formData.append('goal', goal);
    formData.append('industry', industry);
    formData.append('projects', projects);

    // Append all files to formData
    files.forEach((file, index) => {
      formData.append(`files[]`, file);
    });

    // Send data to backend
    try {
      setLoading(true);
      const response = await backend.post('/organization', formData);

      if (response.status === 200 || response.status === 201) {
        setIsFirstLogin(false); // Close the modal
        setLoading(false);
      } else {
        setError('Failed to save organization details');
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setError('An error occurred while saving organization details');
    }
  };

  return (
    <div className="onboarding-overlay">
      <div className="onboarding-modal">
        <h2>Welcome! Let's set up your organization</h2>
        <form onSubmit={handleSubmit}>
          {error && <div className="error">{error}</div>}
          <div className="input-group">
            <label>Organization Name</label>
            <input
              type="text"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Goal</label>
            <textarea
              value={goal}
              onChange={(e) => setGoal(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="input-group">
            <label>Industry</label>
            <select
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              required
            >
              <option value="">Select Industry</option>
              {industries.map((ind) => (
                <option key={ind} value={ind}>
                  {ind}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Projects</label>
            <textarea
              value={projects}
              onChange={(e) => setProjects(e.target.value)}
              placeholder="Describe your projects or upload files below"
            ></textarea>
          </div>
          <div className="input-group">
            <label>Upload Projects (CSV, PDF)</label>
            <input
              type="file"
              accept=".csv,.pdf"
              multiple // Enable multiple file selection
              onChange={handleFileChange}
            />
          </div>

          {/* Display uploaded files with option to remove */}
          {files.length > 0 && (
            <div className="uploaded-files">
              <h4>Uploaded Files:</h4>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <button type="button" onClick={() => removeFile(index)} className="remove-file-btn">
                      &times;
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <button type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save and Continue'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default OnboardingModal;
