import React from 'react';
import '../css/taskbar.css';
import importCSV from '../assets/svgs/import.svg';
import exportCSV from '../assets/svgs/export.svg';
import clearAll from '../assets/svgs/additem.svg';

const Taskbar = ({ onImportCSV, onExportCSV, onClearTable }) => {
    return (
        <div className="taskbar">
            <label htmlFor="file-upload" className="file-upload-label">
                <img src={importCSV} alt="Import CSV" className="icon" /> Import CSV
            </label>
            <input
                type="file"
                id="file-upload"
                accept=".csv"
                onChange={onImportCSV}
                style={{ display: 'none' }}
            />
            <button onClick={onExportCSV} className="taskbar-button">
                <img src={exportCSV} alt="Export as CSV" className="icon" /> Export as CSV
            </button>
            <button onClick={onClearTable} className="taskbar-button">
                <img src={clearAll} alt="Clear Table" className="icon" /> Clear Table
            </button>
        </div>
    );
};

export default Taskbar;
