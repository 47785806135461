import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Spreadsheet from './Spreadsheet'; // Importing the Spreadsheet component here
import '../css/layout.css';

const Layout = () => {

  const [selectedSheet, setSelectedSheet] = useState(null);

  return (
    <div className="layout-container">
     <Sidebar onSheetSelect={setSelectedSheet} /> 
      <div className="main-content">
        <Header /> {/* Header at the top */}
        <div className="content-area">
        <Spreadsheet selectedSheet={selectedSheet} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
