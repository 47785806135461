import React, { useEffect, useState } from 'react';
import { backend } from '../api/axios'; // Import the backend instance
import '../css/sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';

const Sidebar = ({ onSheetSelect }) => {
  const [sheets, setSheets] = useState([]);
  const [error, setError] = useState('');
  const [showNewSheetInput, setShowNewSheetInput] = useState(false); // To show/hide input field
  const [newSheetName, setNewSheetName] = useState(''); // For the new sheet name
  const userId = Cookies.get('userId');

  // Fetch the sheets on component mount
  useEffect(() => {
    const fetchSheets = async () => {
      try {
        const response = await backend.get('/sheets');
        setSheets(response.data.sheets || []); // Assume sheets come under "sheets" key
      } catch (err) {
        setError('Failed to load sheets.');
        console.error('Error fetching sheets:', err);
      }
    };
    fetchSheets();
  }, []);

  // Handle delete
  const handleDeleteSheet = async (sheetId) => {
    try {
      const response = await backend.delete(`/sheets/${sheetId}`);
      if (response.status === 200) {
        setSheets(sheets.filter(sheet => sheet.id !== sheetId)); // Remove from state
      } else {
        setError('Failed to delete the sheet.');
      }
    } catch (err) {
      setError('Error deleting sheet.');
      console.error('Error deleting sheet:', err);
    }
  };

  // Handle creating a new sheet
  const handleCreateNewSheet = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!newSheetName.trim()) {
      setError('Sheet name cannot be empty.');
      return;
    }

    try {
      const response = await backend.post('/sheets', { sheetName: newSheetName, userId: userId });
      if (response.status === 201) {
        setSheets([...sheets, response.data.sheet]); // Add new sheet to the list
        setNewSheetName(''); // Clear the input field
        setShowNewSheetInput(false); // Hide input field
      } else {
        setError('Failed to create the sheet.');
      }
    } catch (err) {
      setError('Error creating sheet.');
      console.error('Error creating sheet:', err);
    }
  };

  return (
    <div className="sidebar">
      <h2>My Sheets</h2>

      {error && <p className="error">{error}</p>}

      {/* Sheets list */}
      <ul className="sheet-list">
        {sheets.length > 0 ? (
          sheets.map(sheet => (
            <li key={sheet.id} className="sheet-item" onClick={() => onSheetSelect(sheet.jsonData)}> {/* Pass jsonData on click */}
              <span>{sheet.sheetName}</span>
              <div className="sheet-options">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  onClick={() => handleDeleteSheet(sheet.id)}
                  className="delete-icon"
                />
              </div>
            </li>
          ))
        ) : (
          <p>No sheets available</p>
        )}
      </ul>

      {/* Show Create New Sheet Button */}
      {!showNewSheetInput && (
        <button className="create-sheet-btn" onClick={() => setShowNewSheetInput(true)}>
          <FontAwesomeIcon icon={faPlus} /> Create New Sheet
        </button>
      )}

      {/* Input for new sheet name */}
      {showNewSheetInput && (
        <form onSubmit={handleCreateNewSheet} className="new-sheet-form">
          <input
            type="text"
            value={newSheetName}
            onChange={(e) => setNewSheetName(e.target.value)}
            placeholder="Enter sheet name"
            className="new-sheet-input"
          />
          <button type="submit" className="new-sheet-submit">
            Create
          </button>
        </form>
      )}
    </div>
  );
};

export default Sidebar;
