// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .login-container h2 {
    text-align: center;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 8px;
  }
  
  .error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .support {
    text-align: center;
    margin-top: 10px;
  }  `, "",{"version":3,"sources":["webpack://./src/css/login.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,UAAU;IACV,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".login-container {\n    max-width: 400px;\n    margin: 100px auto;\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 4px;\n  }\n  \n  .login-container h2 {\n    text-align: center;\n  }\n  \n  .input-group {\n    margin-bottom: 15px;\n  }\n  \n  .input-group label {\n    display: block;\n    margin-bottom: 5px;\n  }\n  \n  .input-group input {\n    width: 100%;\n    padding: 8px;\n  }\n  \n  .error {\n    color: red;\n    text-align: center;\n    margin-bottom: 10px;\n  }\n  \n  .support {\n    text-align: center;\n    margin-top: 10px;\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
