// context/AuthContext.js
import React, { createContext, useState } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const existingTokens = Cookies.get('accessToken') || null;
  const existingUserId = Cookies.get('userId') || null;
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [userId, setUserId] = useState(existingUserId);
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const setTokens = (tokens) => {
    setAuthTokens(tokens);
    // Optionally, you can handle refresh tokens here if you implement them
  };

  return (
    <AuthContext.Provider
      value={{
        authTokens,
        setAuthTokens: setTokens,
        isFirstLogin,
        setIsFirstLogin,
        userId,
        setUserId, 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
