// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    /* height: 8%; */
    height: 70px;
    z-index: 1;
    background-color: #282e31;
    color: white;
    font-family: "Montserrat";
    font-weight: 900;
    position: relative;
    top: 0%;
    display: flex;
    padding: 5px 40px 5px 40px;
}

.title {
    margin: auto 0px;
    /* margin-left: 30px; */
}`, "",{"version":3,"sources":["webpack://./src/css/header.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,OAAO;IACP,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".header {\n    /* height: 8%; */\n    height: 70px;\n    z-index: 1;\n    background-color: #282e31;\n    color: white;\n    font-family: \"Montserrat\";\n    font-weight: 900;\n    position: relative;\n    top: 0%;\n    display: flex;\n    padding: 5px 40px 5px 40px;\n}\n\n.title {\n    margin: auto 0px;\n    /* margin-left: 30px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
