import React, { useState, useRef,useEffect } from 'react';
import { utils, writeFile, read } from 'xlsx';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import Taskbar from './Taskbar';
import '../css/spreadsheet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import { createRoot } from 'react-dom/client';
import { backend } from '../api/axios'; // Import the backend axios instance
// import Cookies from 'js-cookie';


const Spreadsheet = ({ selectedSheet }) => {  
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([]); // Store column names
    const [columnConfig, setColumnConfig] = useState({}); // Store column configurations
    const [showDropdown, setShowDropdown] = useState(null); // Track active dropdown
    const hotTableRef = useRef(null); // Ref for Handsontable instance
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [activeColumnIndex, setActiveColumnIndex] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    // const userId = Cookies.get('userId');

      // Load sheet when selected
      useEffect(() => {
        if (selectedSheet) {
            // Assume selectedSheet is an object with data and columns
            setData(selectedSheet.data || []);
            setColumns(selectedSheet.columns || []);
        }
    }, [selectedSheet]);

    // Add column functionality
    const handleAddColumn = () => {
        const columnName = prompt('Enter column name');
        if (columnName) {
            const updatedColumns = [...columns, columnName];
            setColumns(updatedColumns);
    
            const newData = data.length === 0 ? [[...Array(updatedColumns.length).fill('')]] : data.map(row => [...row, '']);
            setData(newData);
    
            // Set the active column index to the index of the new column
            setActiveColumnIndex(updatedColumns.length - 1); // Last index is the new column
            setShowDropdown(columnName); // Show dropdown to configure the new column
        }
    };
    
    const handleAddRow = () => {
        const newData = [...data, Array(columns.length).fill('')];
        setData(newData);
    };

     // Handle import CSV functionality
     const handleImportCSV = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
            const workbook = read(event.target.result, { type: 'binary' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const csvData = utils.sheet_to_json(sheet, { header: 1 });
            setData(csvData.slice(1)); // Skip header row
            setColumns(csvData[0]); // First row as columns
        } catch (error) {
            alert('Error reading the CSV file. Please ensure it is a valid format.');
            console.error(error);
        }
        };
        reader.readAsBinaryString(file);
    };

    // Handle Export CSV functionality
    const handleExportCSV = () => {
        const hotInstance = hotTableRef.current.hotInstance;
        const currentData = hotInstance.getData();
        const currentColumns = hotInstance.getColHeader();
        const worksheet = utils.aoa_to_sheet([currentColumns, ...currentData]);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        writeFile(workbook, 'spreadsheet_data.csv');
    };

    // Clear Table functionality
    const handleClearTable = () => {
        setData([[]]);
        setColumns([]);
    };

    const handleSubmitConfig = (columnName) => {
        const config = columnConfig[columnName] || {};
        setColumnConfig({ ...columnConfig, [columnName]: config });
        setShowDropdown(null); // Close the dropdown
    };

    // Render custom headers with the dropdown icon
    const handleManualSetup = (columnName, button, index) => {
        const buttonRect = button.getBoundingClientRect();
    
        // Set the position of the dropdown based on the button's position
        setDropdownPosition({
            top: buttonRect.bottom + window.scrollY, // Add scroll offset
            left: buttonRect.left + window.scrollX,
        });
    
        // Toggle the dropdown visibility and set the active column index
            setShowDropdown(prev => (prev === columnName ? null : columnName));
            setActiveColumnIndex(prev => (prev === columnName ? null : index));
    };
    
    const handleRowSelection = (rowIndex) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(rowIndex)) {
                // Remove row from selection
                return prevSelectedRows.filter((row) => row !== rowIndex);
            } else {
                // Add row to selection
                return [...prevSelectedRows, rowIndex];
            }
        });
    };

    const handleRunAi = async (row, col) => {
        const hotInstance = hotTableRef.current.hotInstance;
        const columnName = columns[col];
        const prompt = columnConfig[columnName]?.prompt || '';
        const inputs = columnConfig[columnName]?.inputs || [];
        const tool = columnConfig[columnName]?.tool || 'instructions-only';

        if (!prompt) {
            alert('No prompt defined for this column');
            return;
        }
    
        // Loop through each selected row
        for (const selectedRow of row) {
            // Gather data from the specified input columns for each row
            const inputData = inputs.map((inputCol) => {
                const inputColIndex = columns.indexOf(inputCol);
                return hotInstance.getDataAtCell(selectedRow, inputColIndex) || 'null';
            });
            hotInstance.setDataAtCell(selectedRow, col, 'pending...');
            if (tool === 'crunchbase-only') {
                // Call your backend API for Crunchbase data
                try {
                    const response = await backend.post('company/scrape', { 
                        input_value: inputData.join(', '),
                    
                    });
    
                    const result = await response.json();
                    console.log( result);
                    const fullResponse = (result.summary + result.technology + result.signalsNews ) || 'No data';
    
                    hotInstance.setDataAtCell(selectedRow, col, fullResponse);
                } catch (error) {
                    console.error('Error calling Crunchbase API:', error);
                    alert('Failed to get data from Crunchbase API.');
                }
            } else {
            // Prepare the API payload
            const apiPayload = {
                model: 'gpt-4o-mini',
                messages: [
                    { role: 'system', content: 'You are an AI assistant helping with spreadsheet data. Always provide short and specific responses, ideally single-word or a few words' },
                    { role: 'user', content: `${prompt}\n${inputs.map((input, idx) => `${input}: ${inputData[idx]}`).join('\n')}` }
                ]
            };
    
            try {
                const response = await fetch('https://api.openai.com/v1/chat/completions', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer sk-z7nxt-UcDp4ZGiokK7R_LTMzckl5VmYmlGHHIwbMSST3BlbkFJBmSmg3qpB-Fz3Z_TglqBgA7UImlsFoKlz4ZP9zs0YA',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(apiPayload),
                });
    
                const result = await response.json();
                const fullResponse = result?.choices[0]?.message?.content || 'No data';
    
                const match = fullResponse.match(/is\s*['"](.+?)['"]/);
                const specificAnswer = match ? match[1] : fullResponse;
    
                // Update the specific cell with the answer
                hotInstance.setDataAtCell(selectedRow, col, specificAnswer);
    
            } catch (error) {
                console.error('Error calling OpenAI API:', error);
                alert('Failed to get data from OpenAI.');
            }
        }
        }
    };
    

    const renderHeader = (col, TH) => {
        const columnName = columns[col];
        if (!columnName) return;
    
        while (TH.firstChild) {
            TH.removeChild(TH.firstChild);
        }
    
        const headerDiv = document.createElement('div');
        headerDiv.classList.add('column-header');
        headerDiv.textContent = columnName;
    
        const dropdownButton = document.createElement('button');
        dropdownButton.classList.add('dropdown-icon');
    
        const iconElement = document.createElement('div');
        const root = createRoot(iconElement);
        root.render(<FontAwesomeIcon icon={faCircleChevronDown} />); 
        dropdownButton.appendChild(iconElement);
    
        headerDiv.appendChild(dropdownButton);
        TH.appendChild(headerDiv);
    
        dropdownButton.addEventListener('click', () => handleManualSetup(columnName, dropdownButton, col));
    
        // Add "Run" button if AI is enabled for the column
        const config = columnConfig[columnName];
        if (config?.aiEnabled) {
            const runButton = document.createElement('button');
            runButton.textContent = 'Run';
            runButton.classList.add('run-button');
            runButton.addEventListener('click', () => {
                if (selectedRows.length > 0) {
                    console.log("selectedrow", selectedRows)
                    handleRunAi(selectedRows, col); // Passed +1 in row then it took the first row
                } else {
                    alert('Please select a row first.');
                }
            });
            TH.appendChild(runButton);
        }
    };

    const renderRowHeader = (row, TH) => {
        // Ensure TH exists
        if (!TH) return;
    
        // Clear previous content of the TH element
        while (TH.firstChild) {
            TH.removeChild(TH.firstChild);
        }
    
        const headerDiv = document.createElement('div');
        headerDiv.classList.add('row-header-container');
    
        // Create a checkbox for selecting rows
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.checked = selectedRows.includes(row);
    
        // Add an event listener to handle row selection
        checkbox.addEventListener('change', (e) => {
            e.stopPropagation(); // Prevents other row events from firing
            handleRowSelection(row);
        });
    
        // Add checkbox and row number to the row header
        headerDiv.appendChild(checkbox);
        headerDiv.appendChild(document.createTextNode(row + 1)); // Display row number
    
        // Append the headerDiv (with checkbox and row number) to the TH element
        TH.appendChild(headerDiv);
    };

    return (
        <div className="spreadsheet-container">
            <Taskbar 
                onImportCSV={handleImportCSV} 
                onExportCSV={handleExportCSV} 
                onClearTable={handleClearTable} 
            />

            <div className="add-buttons">
                <button className="add-button" onClick={handleAddColumn}>Add Column</button>
                <button className="add-button" onClick={handleAddRow}>Add Row</button>
            </div>

            <div className="spreadsheet-table-wrapper">
                <HotTable
                    ref={hotTableRef}
                    data={data}
                    colHeaders={columns}
                    rowHeaders={true}
                    contextMenu={true}
                    copyPaste={true}
                    manualColumnResize={true}
                    manualRowResize={true}
                    fillHandle={true}
                    columnSorting={true}
                    filters={true}
                    colWidths={150}
                    width="100%"
                    height="83vh"
                    className="dark-theme-table"
                    afterChange={(changes, source) => {
                        if (changes) {
                            const [row, col, oldValue, newValue] = changes[0];
                            const columnName = columns[col];
                            const config = columnConfig[columnName];
                        }
                    }}
                    
                    afterGetRowHeader={renderRowHeader}
                    afterGetColHeader={renderHeader}
                    licenseKey="non-commercial-and-evaluation"
                />
            </div>

            {showDropdown && (
                <div className="dropdown"
                        style={{
                            position: 'absolute',
                            top: `${dropdownPosition.top}px`,
                            left: `${dropdownPosition.left}px`,
                        }}
                >
                    <h3>{showDropdown}</h3>
                    <div className="editable-column-container">
                        <h4>Column Name</h4>
                        <textarea
                            value={columns[activeColumnIndex] || ''} // Use activeColumnIndex to get the correct column name
                            onChange={(e) => {
                                const updatedColumnName = e.target.value;
                                const updatedColumns = [...columns];
                                updatedColumns[activeColumnIndex] = updatedColumnName; // Update the specific column by index
                                setColumns(updatedColumns);

                                // Update showDropdown only if updatedColumnName is not empty
                                if (updatedColumnName.trim() !== '') {
                                    setShowDropdown(updatedColumnName); // Update dropdown name as well
                                }
                            }}
                            className="editable-column"
                        />
                    </div>
                    <label>
                        <input
                            type="checkbox"
                            checked={columnConfig[showDropdown]?.aiEnabled || false}
                            onChange={(e) =>
                                setColumnConfig({
                                    ...columnConfig,
                                    [showDropdown]: {
                                        ...columnConfig[showDropdown],
                                        aiEnabled: e.target.checked,
                                    },
                                })
                            }
                        />
                        Enable AI Feature
                    </label>
                    <div className="tools-options">
                        <h4>Tools</h4>
                        <select
                            value={columnConfig[showDropdown]?.tool || ''}
                            onChange={(e) =>
                                setColumnConfig({
                                    ...columnConfig,
                                    [showDropdown]: {
                                        ...columnConfig[showDropdown],
                                        tool: e.target.value,
                                    },
                                })
                            }
                        >
                            <option value="instructions-only">Instructions Only</option>
                            <option value="crunchbase-only">Crunchbase Only</option> {/* New Option */}
                        </select>
                    </div>
                    <div className="inputs-options">
                        <h4>Inputs</h4>
                        {columns
                            .filter((col) => col !== showDropdown)
                            .map((col) => (
                                <label key={col}>
                                    <input
                                        type="checkbox"
                                        checked={
                                            columnConfig[showDropdown]?.inputs?.includes(col) ||
                                            false
                                        }
                                        onChange={(e) => {
                                            const inputs = columnConfig[showDropdown]?.inputs || [];
                                            const newInputs = e.target.checked
                                                ? [...inputs, col]
                                                : inputs.filter((input) => input !== col);
                                            setColumnConfig({
                                                ...columnConfig,
                                                [showDropdown]: {
                                                    ...columnConfig[showDropdown],
                                                    inputs: newInputs,
                                                },
                                            });
                                        }}
                                    />
                                    {col}
                                </label>
                            ))}
                    </div>
                    <div className="prompt-option">
                        <h4>Prompt</h4>
                        <textarea
                            value={columnConfig[showDropdown]?.prompt || ''}
                            onChange={(e) =>
                                setColumnConfig({
                                    ...columnConfig,
                                    [showDropdown]: {
                                        ...columnConfig[showDropdown],
                                        prompt: e.target.value,
                                    },
                                })
                            }
                        />
                    </div>
                    <button onClick={() => handleSubmitConfig(showDropdown)}>Submit</button>
                </div>
            )}
        </div>
    );
};

export default Spreadsheet;
