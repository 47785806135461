// api/axios.js
import axios from 'axios';
import Cookies from 'js-cookie';

const backendURL = 'http://localhost:8000';
const mlURL = 'http://localhost:9002';

const backend = axios.create({
  baseURL: backendURL,
});

const ml = axios.create({
  baseURL: mlURL,
});

export function setAccessToken(token) {
  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 5);
  Cookies.set('accessToken', token, { expires: expiration });
}

export function setUserId(userId) {
    const expiration = new Date();
    expiration.setHours(expiration.getDate() + 5);
    Cookies.set('userId', userId, { expires: expiration });
  }

backend.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

backend.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      Cookies.remove('accessToken');
      // Optionally, you can redirect to login or dispatch a logout action
    }
    return Promise.reject(error);
  }
);

export { backend, ml };
